import React, { useState } from 'react'
import { AiFillGithub, AiFillLinkedin, AiOutlineDownload  } from 'react-icons/ai'
import { FaPaperPlane } from 'react-icons/fa'
import { MdWavingHand } from 'react-icons/md'
import photo from '../../assets/profil.png'
import { motion } from 'framer-motion'

const Home = () => {
    const [isLoaded, setIsLoaded] = useState(false);


  return (
    <div id="home" className='w-full min-h-[calc(100vh-50px)] background bg-blackNight'>
        <div className='myContainer flex-col smd:flex-row smd:gap-0 gap-10 justify-evenly mt-20 xl:mt-32'>
            <motion.div className='flex flex-col-reverse xs:flex-row gap-4 max-w-[500px]'
                initial={{opacity: 0, x: -100}}
                animate={{opacity: 1, x: 0}}
                transition={{ duration: 0.5 }}
            >
                <div className='flex flex-row xs:flex-col items-center text-whitePowder text-[25px] relative gap-4 justify-center'>
                    <div className='flex w-[40px] justify-end  '>
                        <a href="https://github.com/kamilwitek98/portfolio-repository" target="_blank"><AiFillGithub className='rotate-45 hover:rotate-0 duration-100 ease-linear hover:mr-2' /></a>             
                    </div>
                    <div className='flex w-[40px] justify-end '>
                        <a href="https://www.linkedin.com/in/kamil-witek-185474253/" target="_blank"><AiFillLinkedin className='rotate-45 hover:rotate-0 duration-100 ease-linear hover:mr-2' /></a>
                    </div>
                </div>
                <div className='flex flex-col text-whitePowder font-poppins tracking-wide'>
                    <div className='flex flex-row text-[28px] items-center'>
                        <p>Hello</p>
                        <MdWavingHand className='ml-1'/>
                        <p>, I Am</p>
                    </div>
                    <p className='text-[43px] font-semibold mb-6'> Kamil Witek</p>
                    <p className='text-[20px]'>At the moment I' am improving my frontend and backend skills in React.js and Node.js, looking for first job.</p>
                    <div className='flex flex-row mt-10 w-full gap-4 flex-wrap'>
                        <a href="#contact"><button className='text-whitePowder buttonStyle flex  bg-blackNightLight w-[180px] border hover:bg-grayNight transition-all duration-100 items-center justify-center h-[45px] rounded-xl gap-2 '>Contact me <FaPaperPlane /> </button></a>
                        
                    </div>
                </div>
            </motion.div>
          <div className='flex flex-row  justify-center smd:min-w-[350px]'>

            <motion.div className={` max-w-[350px] max-h-[350px] overflow-hidden photoContainer  ${isLoaded ? "flex" : "hidden" }`}
                initial={{opacity: 0, scale: 0}}
                animate={{opacity: 1, scale: 1}}
                transition={{ duration: 0.5 }}
            >
                <img src={photo} onLoad={() => setIsLoaded(true)} alt='photo' className='w-full h-full' />
            </motion.div>
          </div>
        </div>
    </div>
  )
}

export default Home