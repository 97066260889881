import React from 'react'
import './Portfolio.scss'
import { useEffect, useState } from 'react'
import { urlFor, client } from '../../client'
import { motion } from 'framer-motion'
import { BsArrowRightShort, BsArrowLeftShort } from 'react-icons/bs';


const Portfolio = () => {

    const scrollRef = React.useRef(null);
    const scroll = (direction) => {
        const { current } = scrollRef;

        if(direction === 'left'){
        current.scrollLeft -= current.offsetWidth;
        } else {
        current.scrollLeft += current.offsetWidth;
        }
    }

    const [works, setWorks] = useState([]);

    useEffect(() => {
        const query = '*[_type == "works"]';
        
        client.fetch(query)
        .then((data) => {
            setWorks(data);
        })
    }, [])

    return (
        <div id="portfolio" className='w-full bg-blackNightLight font-poppins background'>
            <div className='myContainer flex-col'>
                <div className='app__works' id='works'>
                <motion.h1 className='xs:text-[40px] text-[30px] text-center mb-5 sm:mb-10 text-whitePowder font-semibold'
                whileInView={{opacity: [0, 1]}}
                transition={{ duration: 0.5 }}
                >My projects</motion.h1>
                <div className='app__works-container' ref={scrollRef}>
                    {works.map((work, index) => (
                    <motion.div 
                    whileInView={{opacity: [0, 1]}}
                    transition={{ duration: 0.5 }}
                    className='works__work ' key={'work' + index}>
                        <div className='works__work-image workContainer overflow-hidden'>
                        <a href={work.projectLink} target="_blank" rel='noreferrer'>
                        <img src={urlFor(work.imgUrl)} alt='image'/>
                        </a>
                        </div>
                        <p>{work.description}</p>
                    </motion.div>
                    ))}
                </div>
                    <BsArrowLeftShort className='works__arrow-icon-left works__arrows' onClick={() => scroll('left')}/>
                    <BsArrowRightShort className='works__arrow-icon-right works__arrows' onClick={() => scroll('right')}/>
                </div>
            </div>
        </div>
    )
}

export default Portfolio