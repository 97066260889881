import React from 'react'
import { Navbar, Home, About, Skills, Portfolio, Contact } from './components'

const App = () => {
  return (
    <div className='flex flex-col w-full'>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Portfolio />
      <Contact />
    </div>
    
  )
}

export default App