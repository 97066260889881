import React, { useRef } from 'react'
import { FiPhoneCall } from 'react-icons/fi'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { BiMailSend } from 'react-icons/bi'
import emailjs from '@emailjs/browser'
import { TextField } from '@mui/material'

const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        var element = document.getElementById("formMessage");

        

        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });

        element.reset();
    };

  return (
    <div id="contact" className='w-full background bg-blackNight font-poppins'>
        <div className='myContainer items-center justify-center flex flex-col font-poppins text-whitePowder'>
            <h1 className='xs:text-[40px] text-[30px] text-center mb-10 text-whitePowder font-semibold'>Contact</h1>   
            <div className='flex flex-col items-center sm:flex-row w-full justify-center gap-10'>
                <div className='max-w-[300px] flex flex-col h-full gap-10 w-full '>
                    <a href='mailto:kamil.witek0398@gmail.com'>
                        <div className=' w-full flex flex-col arrowContainer rounded bg-grayNight p-4 text-center items-center'>
                            
                            <BiMailSend className='text-[30px] mb-2'/>
                            <p className='text-[18px] tracking-wide'>Email</p>
                            <p className='text-[14px] font-thin text-gray-200 mb-4'>kamil.witek0398@gmail.com</p>

                            <div className='flex flex-row w-full gap-1 justify-center items-center'>
                                <p className='flex-1 flex flex-row  justify-end'>Write me <span></span></p>
                                <div className='flex-[0.7]'>
                                    <HiArrowNarrowRight className='arrow'/>
                                </div>
                            </div>
                        </div>
                    </a >
                    <a href='tel: +48662447952'>
                        <div className=' w-full flex flex-col arrowContainer rounded bg-grayNight p-4 text-center items-center'>
                            
                            <FiPhoneCall className='text-[30px] mb-2'/>
                            <p className='text-[18px] tracking-wide'>Phone</p>
                            <p className='text-[14px] font-thin text-gray-200 mb-4'>+48 662 447 952</p>

                            <div className='flex flex-row w-full gap-1 justify-center items-center'>
                                <p className='flex-1 flex flex-row  justify-end'>Call me <span></span></p>
                                <div className='flex-[0.7]'>
                                    <HiArrowNarrowRight className='arrow'/>
                                </div>
                            </div>
                        </div>
                    </a > 
                </div>
                <div className='flex flex-col w-full max-w-[600px]'>
                    <form id='formMessage' ref={form} onSubmit={sendEmail}  className='text-whitePowder flex flex-col gap-4 p-4 rounded bg-grayNight'>
                        <TextField  name="user_name" sx={{input: {color: '#FFFFFA'}, label: {color: '#FFFFFA'}, }}   variant='outlined' required fullWidth label="Name" type='text' />
                        <TextField  name="user_email" sx={{input: {color: '#FFFFFA'}, label: {color: '#FFFFFA'}, }}   variant='outlined' required fullWidth label="Email" type='email' />
                        <TextField multiline rows={4}   name="message" sx={{textarea: {color: '#FFFFFA'}, label: {color: '#FFFFFA'}, }}   variant='outlined' required fullWidth label="Message" type='text' />
                        <button type='submit' className='text-whitePowder buttonStyle flex  bg-blackNightLight w-[180px] border hover:bg-grayNight transition-all duration-100 items-center justify-center h-[45px] rounded-xl gap-2 '>Send Message <BiMailSend /> </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact