import React, { useState, useEffect } from 'react'
import { urlFor, client } from '../../client'
import { motion } from 'framer-motion';
import cv from '../../assets/Kamil_Witek_CV.pdf'
import './skills.scss'
import { AiOutlineDownload } from 'react-icons/ai'

const Skills = () => {
    const [skills, setSkills] = useState([]);

    useEffect(() => {
        const query = '*[_type == "skills"]';
        
    
        client.fetch(query)
          .then((data) => {
            setSkills(data);
          })

      }, [])


  return (
    <div id="skills" className='w-full background bg-blackNight font-poppins'>
        <div className='myContainer items-center justify-center flex flex-col'>
        <h1 className='xs:text-[40px] text-[30px] text-center mb-10 text-whitePowder font-semibold'>Skills</h1>
            <div className='about__skills max-w-[600px] items-center justify-center'>

            
            {skills.map((skill, index) => (
            <motion.div
            className={`skillDiv h-[60px] w-[60px] xs:w-[100px] xs:h-[100px]`} 
            whileInView={{opacity: [0, 1]}}
            transition={{ duration: 0.5 }}
                key={skill.name + index}>
                <img src={urlFor(skill.icon)} alt={skill.name} />
                <div><p>{skill.level}</p></div>
            </motion.div>
            ))}
        </div>
        <a href={cv} download="Kamil_Witek_CV.pdf"><button className='text-whitePowder buttonStyle mt-6 flex  bg-blackNightLight w-[180px] border hover:bg-grayNight transition-all duration-100 items-center justify-center h-[45px] rounded-xl gap-2 '>Download CV <AiOutlineDownload /> </button></a>
        </div>
    </div>  
    
  )
}

export default Skills