import React, { useEffect, useState } from 'react'
import { urlFor, client } from '../../client'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { HiOutlineDesktopComputer } from 'react-icons/hi'
import { SlGraduation } from 'react-icons/sl'

const About = () => {
    const [experience, setExperience] = useState([]);
    const [education, setEducation] = useState([]);

    useEffect(() => {
        const queryExperience = '*[_type == "experience"]';
        const queryEducation = '*[_type == "education"]';

        client.fetch(queryEducation)
            .then((data) => {
                setEducation(data);
                console.log(education);
            })
      
        client.fetch(queryExperience)
            .then((data) => {
                setExperience(data);
                console.log(experience);;
            })
        
        
    }, [])
    
  return (
    <div id="about" className='w-full bg-blackNightLight font-poppins background'>
        <div className='myContainer flex-col'>
            <h1 className='xs:text-[40px] text-[30px] text-center mb-10 text-whitePowder font-semibold'>Education and Experience</h1>
            <VerticalTimeline className='text-whitePowder'>
                {
                    education?.map(element => {

                        
                        return(
                        <VerticalTimelineElement
                            key={element._id}
                            date={element.date}
                            dateClassName='date'
                            iconStyle={{background: "#1C1E23", color: 'black'}}
                            contentStyle={{background: '#2F323B'}}
                            contentArrowStyle={{borderRightColor: '#2F323B'}}
                            icon={<SlGraduation style={{color: 'white'}} />}
                        >
                            <h3 className='vertical-timeline-element-title text-[16px] xs:text-[20px] mb-2 leading-[24px]'>{element.school}</h3>
                            <h5 className='vertical-timeline-element-subtitle text-[12px] xs:text-[14px] text-gray-200'>{element.type}</h5>
                        </VerticalTimelineElement>
                        )
                        })
                }

                {
                    experience?.map(element => {

                        
                        return(
                        <VerticalTimelineElement
                            key={element._id}
                            date={element.date}
                            dateClassName='date'
                            iconStyle={{background: "#1C1E23", color: 'black'}}
                            contentStyle={{background: '#2F323B'}}
                            contentArrowStyle={{borderRightColor: '#2F323B'}}
                            icon={<HiOutlineDesktopComputer style={{color: 'white'}}  />}
                        >
                            <h3 className='vertical-timeline-element-title text-[16px] xs:text-[20px] mb-2 leading-[24px]'>{element.company}</h3>
                            <h5 className='vertical-timeline-element-subtitle text-[12px] xs:text-[14px] text-gray-200'>{element.position}</h5>
                            {
                                element.hasOwnProperty('tasks') &&
                                <div className='mt-5'>
                                    {
                                        element.tasks.map(task => (
                                    
                                            <li className=' text-[13px] xs:text-[15px] list-none ml-2 '>{task}</li>
                                        ))
                                    }
                                </div>
                                
                            }
                        </VerticalTimelineElement>
                        )
                        })
                }


            </VerticalTimeline>
        </div>
    </div>
  )
}

export default About