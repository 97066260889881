import React, { useState } from 'react'
import { AiOutlineCloseCircle, AiOutlineMenu } from 'react-icons/ai';
import { motion } from 'framer-motion';

const Navbar = () => {

    const [toggle, setToggle] = useState(false);

  const variants = {
    open: {x: -200},
    closed: {x: 0},
  }
  return (
    <nav className='w-full flex items-center justify-center bg-blackNight text-whitePowder font-poppins h-[50px] px-2 xs:px-4 sm:px-6'>
        <div className='headerContainer justify-end p-0'>
            <ul className='hidden flex-row gap-6 xs:flex'>
                <li><a href='#home'>Home</a></li>
                <li><a href='#about'>About</a></li>
                <li><a href='#skills'>Skills</a></li>
                <li><a href='#portfolio'>Portfolio</a></li>
                <li><a href='#contact'>Contact</a></li>
            </ul>
            <div className='xs:hidden flex items-center justify-center text-[30px]'>
                <AiOutlineMenu onClick={() => setToggle(true)}/>
            </div>
        </div>
        
            <motion.div
                className='fixed bg-blackNight w-[150px] text-whitePowder right-[-200px] gap-4 top-0 p-2 font-poppins rounded flex justify-center flex-col'
                animate={toggle ? 'open' : 'closed'}
                variants={variants}
                transition={{duration: 0.3, ease: 'easeOut'}}
            >
                <div className='w-full flex justify-end mb-4 text-[28px]'>
                <AiOutlineCloseCircle onClick={() => setToggle(false)}/> 
                </div> 

                <ul className='flex flex-col w-full justify-center gap-2 text-[18px] pl-2'>
                <li><a href="#home" onClick={() => setToggle(false)}>Home</a></li>
                <li><a href="#about" onClick={() => setToggle(false)}>About</a></li>
                <li><a href="#portfolio" onClick={() => setToggle(false)}>Portfolio</a></li>
                <li><a href="#skills" onClick={() => setToggle(false)}>Skills</a></li>
                <li><a href="#contact" onClick={() => setToggle(false)}>Contact</a></li>
                </ul>
            </motion.div>
          
        
      
    </nav>
  )
}

export default Navbar